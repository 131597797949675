import * as React from "react"
import Layout from "../components/layout"
import Seo from "../components/seo"
import { StaticImage } from "gatsby-plugin-image"

const LogPackages = () => {

  return (
    <Layout formTitle="Have a question about our log home packages? Let us know.">
      <Seo 
        title="Log Home Packages"
        description="A log cabin shell package is everything you need to erect a weather-tight log home. We also offer complete packages that include interior materials." 
      />
      <div className="container mx-auto pt-32 md:pt-48">
        <div className="relative flex items-center justify-center bg-header overflow-hidden">
          <h1 className="text-white px-6 py-32 md:px-12 md:py-64">Log Home Packages</h1>
          <StaticImage
            src="../images/log_home_packages_header.jpg"
            alt="a custom log home in Vermont"
            placeholder="blurred"
            className="header-bg-img"
          />
        </div>
      </div>
      <div className="container mx-auto mt-16 md:mt-32">
        <h2>Shell Package</h2>
        <p className="max-w-3xl mb-12">A shell package is everything you need to erect a weather-tight log home. The list below details what is included in a shell package.</p>
        <h3 className="mb-8">Log Wall System</h3>
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-6 md:gap-12 mb-16 md:mb-24">
          <div className="p-6 border">
            <h4>Logs</h4>
            <p className="text-base mb-0">8" x 6", Hand Drawshaved, D-Profile, Northern White Cedar logs, naturally air-dryed 1 yr, Tounge &amp; V-Groove system, pre-cut, lettered and numbered.</p>
          </div>
          <div className="p-6 border">
            <h4>Assembly Prints</h4>
            <p className="text-base mb-0">Easy-to-Read log assembly instructions w/ complete list of materials.</p>
          </div>
          <div className="p-6 border">
            <h4>Windows</h4>
            <p className="text-base mb-0">PELLA windows and screens. VELUX ventilating Skylights with flashing and headers.</p>
          </div>
          <div className="p-6 border">
            <h4>Exterior Doors</h4>
            <p className="text-base mb-0">PELLA exterior doors w/ lock set, screen, and wood grills.</p>
          </div>
          <div className="p-6 border">
            <h4>Foam Gasket</h4>
            <p className="text-base mb-0">Premium All-Weather gasket.</p>
          </div>
          <div className="p-6 border">
            <h4>Caulking</h4>
            <p className="text-base mb-0">Premium log wall caulking.</p>
          </div>
          <div className="p-6 border">
            <h4>Screws</h4>
            <p className="text-base mb-0">9" log wall assembly screws.</p>
          </div>
          <div className="p-6 border">
            <h4>Spline</h4>
            <p className="text-base mb-0">3/4" Cedar for joining all window/ door openings, and log-end joints.</p>
          </div>
          <div className="p-6 border">
            <h4>Framing Stock</h4>
            <p className="text-base mb-0">Pre-cut for assembly around doors and windows.</p>
          </div>
          <div className="p-6 border">
            <h4>Exterior Trim</h4>
            <p className="text-base mb-0">1" matched cedar boards for all exterior trim.</p>
          </div>
          <div className="p-6 border">
            <h4>Miscellaneous</h4>
            <p className="text-base mb-0">Sill Seal and Shim Shingles.</p>
          </div>
        </div>
        <h3 className="mb-8">First Floor System</h3>
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-6 md:gap-12 mb-16 md:mb-24">
          <div className="p-6 border">
            <h4>Floorplan</h4>
            <p className="text-base mb-0">Fully Engineered Floorplan</p>
          </div>
          <div className="p-6 border">
            <h4>Sub Floor</h4>
            <p className="text-base mb-0">3/4" advantech sub floor</p>
          </div>
        </div>
        <h3 className="mb-8">Second Floor System</h3>
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-6 md:gap-12 mb-16 md:mb-24">
          <div className="p-6 border">
            <h4>Carrying Beams</h4>
            <p className="text-base mb-0">8" x 12" S3S</p>
          </div>
          <div className="p-6 border">
            <h4>Floor Joists</h4>
            <p className="text-base mb-0">4" x 8", S3S or full log, 36" O.C.</p>
          </div>
          <div className="p-6 border">
            <h4>Carrying Beams Posts</h4>
            <p className="text-base mb-0">8" x 8", s4s or full log, cedar</p>
          </div>
        </div>
        <h3 className="mb-8">Roof System</h3>
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-6 md:gap-12 mb-16 md:mb-24">
          <div className="p-6 border">
            <h4>Rafters</h4>
            <p className="text-base mb-0">Conventional rafters, round log rafters, or square timber roof structure</p>
          </div>
          <div className="p-6 border">
            <h4>Truss Rafters</h4>
            <p className="text-base mb-0">2' O.C.</p>
          </div>
          <div className="p-6 border">
            <h4>Fly Rafters</h4>
            <p className="text-base mb-0">2" x 10" S3S, round log fly rafters, or square timbers</p>
          </div>
          <div className="p-6 border">
            <h4>Ridge Beam</h4>
            <p className="text-base mb-0">4"x14" or 6"x14" spruce</p>
          </div>
          <div className="p-6 border">
            <h4>Sheathing</h4>
            <p className="text-base mb-0">5/8" osb T&amp;G</p>
          </div>
          <div className="p-6 border">
            <h4>Roofing Paper</h4>
            <p className="text-base mb-0">Tri-Flex, Ice &amp; Water Shield for valleys</p>
          </div>
          <div className="p-6 border">
            <h4>Shingles</h4>
            <p className="text-base mb-0">Cambridge 30 Yr. Warranty (Steel Optional)</p>
          </div>
          <div className="p-6 border">
            <h4>Drip-Edge</h4>
            <p className="text-base mb-0">8" standard</p>
          </div>
          <div className="p-6 border">
            <h4>Soffit Vent</h4>
            <p className="text-base mb-0">2" standard</p>
          </div>
          <div className="p-6 border">
            <h4>Ridge Vent</h4>
            <p className="text-base mb-0">Rolled Ridge Vent</p>
          </div>
          <div className="p-6 border">
            <h4>Gable Overhang</h4>
            <p className="text-base mb-0">1" TVG Cedar</p>
          </div>
        </div>
        <h3 className="mb-8">Dormer Systems</h3>
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-6 md:gap-12 mb-16 md:mb-24">
          <div className="p-6 border">
            <h4>Framing</h4>
            <p className="text-base mb-0">2" x 6" Spruce or Cedar Log</p>
          </div>
          <div className="p-6 border">
            <h4>Sheathing</h4>
            <p className="text-base mb-0">1/2" CDX Plywood (Walls)</p>
          </div>
          <div className="p-6 border">
            <h4>House Wrap</h4>
            <p className="text-base mb-0">Typar</p>
          </div>
          <div className="p-6 border">
            <h4>Siding</h4>
            <p className="text-base mb-0">1" Board &amp; Batten, 1" Tongue &amp; V-Groove, or Log Siding</p>
          </div>
          <div className="p-6 border">
            <h4>Dormer Roof System</h4>
            <p className="text-base mb-0">Rafters, Sheathing, Roofing Paper, Shingles/Steel, and Drip-Edge included.</p>
          </div>
        </div>
        <h3 className="mb-8">Porch &amp; Deck Systems</h3>
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-6 md:gap-12 mb-16 md:mb-24">
          <div className="p-6 border">
            <h4>Log Sills &amp; Beams</h4>
            <p className="text-base mb-0">Cedar S4S</p>
          </div>
          <div className="p-6 border">
            <h4>Floor Joists</h4>
            <p className="text-base mb-0">Pressure treated 16" O.C.</p>
          </div>
          <div className="p-6 border">
            <h4>Decking</h4>
            <p className="text-base mb-0">5/4" Eased-Edge Cedar</p>
          </div>
          <div className="p-6 border">
            <h4>Posts</h4>
            <p className="text-base mb-0">6" x 8", S3S or full-log, Cedar</p>
          </div>
          <div className="p-6 border">
            <h4>Rafters</h4>
            <p className="text-base mb-0">4" x 6", S3S or full-log, Spruce or Fir</p>
          </div>
          <div className="p-6 border">
            <h4>Roof Sheathing</h4>
            <p className="text-base mb-0">1" x 8" T&amg;VG pine</p>
          </div>
          <div className="p-6 border">
            <h4>Ceiling</h4>
            <p className="text-base mb-0">1" x 8" T&amg;VG pine</p>
          </div>
          <div className="p-6 border">
            <h4>Porch Roof System</h4>
            <p className="text-base mb-0">Shingles/Steel, Roofing Paper, and Drip-Edge included.</p>
          </div>
        </div>
        <h2>Complete Package</h2>
        <p className="max-w-3xl mb-12">A complete package is everything in the shell package plus interior materials. The list below details what is included additionally in a complete package.</p>
        <h3 className="mb-8">Interior Materials</h3>
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-6 md:gap-12 mb-16 md:mb-24">
          <div className="p-6 border">
            <h4>Partition Stock</h4>
            <p className="text-base mb-0">2" x 4"'s and 2" x 6"'s, Spruce</p>
          </div>
          <div className="p-6 border">
            <h4>Walls &amp; Ceilings</h4>
            <p className="text-base mb-0">1" x 4", 1" x 5", or 1" x 6" TVG Cedar</p>
          </div>
          <div className="p-6 border">
            <h4>Door &amp; Window Casings</h4>
            <p className="text-base mb-0">1" x4" &amp; 1" x 6" Sq-Edge Cedar</p>
          </div>
          <div className="p-6 border">
            <h4>Flooring (2nd Floor)</h4>
            <p className="text-base mb-0">1 1/2" T&amg;VG lodge pole pine or 1 1/2" Russian spruce</p>
          </div>
          <div className="p-6 border">
            <h4>Stair Systems</h4>
            <p className="text-base mb-0">2" x 12" Spruce Stringers, 2" x 10" Pine Treads, 1" x 8" Pine Risers, or 1/2" log treads and stingers</p>
          </div>
          <div className="p-6 border">
            <h4>Insulation</h4>
            <p className="text-base mb-0">Spray foam insulation</p>
          </div>
        </div>
        <h2>Additional Information</h2>
        <p className="mb-2"><strong>1. These lists may differ for some designs.</strong></p>
        <p className="mb-2"><strong>2. Log Options -</strong></p>
        <ul className="md:columns-2 lg:columns-3 mb-4">
        <li className="text-base mb-2">8" x 6" - Drawshaved - D-shape - Cedar</li>
        <li className="text-base mb-2">8" x 6" - Milled - D-shape - Cedar</li>
        <li className="text-base mb-2">8" x 6" - Milled - D-shape - Pine</li>
        <li className="text-base mb-2">7" x 5" - Drawshaved - D-shape - Cedar</li>
        <li className="text-base mb-2">7" x 5" - Milled - D-shape - Cedar</li>
        <li className="text-base mb-2">7" x 5" - Milled - Round-shape - Cedar</li>
        </ul>
        <p className="mb-2"><strong>3. We can customize, modify, or combine elements of these packages.</strong></p>
        <hr className="mt-32 md:mt-40"/>        
      </div>
    </Layout>
  );
}
 
export default LogPackages;